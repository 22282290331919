import { Button } from '@components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@components/ui/dialog';
import { Input } from '@components/ui/input';
import {
  PRICE_ID_REVENUE,
  PRO_BUNDLE_PRICE_ID,
  SINGLE_PACK_PRICE_ID,
  STARTER_PRICE_ID,
  SUBSCRIPTION_PLAN
} from '@/_data/constants';
import { getCheckoutSession } from '@/_data/stripe/getCheckoutSession';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useSession } from '@hooks/index';
import { cn } from '@lib/utils';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import * as React from 'react';
import { CgSpinner } from 'react-icons/cg';
import { useTrackEvent } from '@analytics/trackEvent';
import { sendGTMEvent } from '@next/third-parties/google';
import { Alert, AlertDescription, AlertTitle } from '@components/ui/alert';

const tiers = [
  {
    name: 'Starter Kit',
    id: 'tier-starter',
    image: '/starter-kit.png',
    price: '$' + PRICE_ID_REVENUE[STARTER_PRICE_ID],
    features: [
      'One-time setup fee',
      '40 photos',
      'One photo pack',
      'Delivered in 30 mins',
      'Additional packs just $' + PRICE_ID_REVENUE[SINGLE_PACK_PRICE_ID]
    ],
    mostPopular: false
  },
  {
    name: 'Pro Bundle',
    id: 'tier-pro-bundle',
    image: '/pro-bundle.png',
    price: '$' + PRICE_ID_REVENUE[PRO_BUNDLE_PRICE_ID],
    features: [
      'One-time setup fee',
      '120 photos',
      'Three photo packs',
      'Delivered in 30 mins',
      'Additional packs just $' + PRICE_ID_REVENUE[SINGLE_PACK_PRICE_ID]
    ],
    mostPopular: true
  }
];

interface PricingProps {
  coupon?: string;
  discountPercent?: number;
  expiresAt?: number;
}

export default function Pricing({ coupon, discountPercent = 20, expiresAt }: PricingProps) {
  const router = useRouter();
  const { trackEvent } = useTrackEvent();
  const { isLoggedIn, isLoading, user } = useSession();

  const [isGettingSession, setIsGettingSession] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isGiftDialogOpen, setIsGiftDialogOpen] = React.useState(false);
  const [friendsEmail, setFriendsEmail] = React.useState('');
  const [tier, setTier] = React.useState<(typeof tiers)[0] | null>(null);

  const calculatePrice = (originalPrice: string) => {
    const priceNumber = parseFloat(originalPrice.replace('$', ''));
    const discountedPrice = priceNumber * (1 - discountPercent / 100);
    return {
      original: `$${priceNumber}`,
      discounted: `$${discountedPrice.toFixed(2)}`
    };
  };

  const tiersWithDiscount = tiers.map(tier => ({
    ...tier,
    prices: calculatePrice(tier.price)
  }));

  const handlePay = async (tier: (typeof tiers)[0]) => {
    if (isGift) {
      setTier(tier);
      setIsGiftDialogOpen(true);
      return;
    }

    if (!isLoggedIn) {
      const currentPathname = router.pathname;
      router.push({
        pathname: '/login',
        query: { redirect: '/pricing', source: currentPathname }
      });
    } else {
      setIsGettingSession(true);
      try {
        if (coupon || user?.subscriptionPlan === SUBSCRIPTION_PLAN.picstudio_drive) {
          trackEvent('initiateCheckout', {
            value: tier.id
          });
          sendGTMEvent({
            event: 'initiateCheckout',
            value: tier.id
          });
          const session = await getCheckoutSession(tier.id, undefined, coupon);
          router.push(session.url);
        } else {
          router.push('/drive?tier=' + tier.id);
        }
      } catch (error) {
        console.error('Error during checkout process:', error);
        setErrorMessage('An error occurred. Please try again.');
      } finally {
        setIsGettingSession(false);
      }
    }
  };

  const isGift = router.query.gift === 'true';

  const handleSubmitGift: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    if (!tier) return;
    setIsGettingSession(true);
    const session = await getCheckoutSession(tier.id, friendsEmail);
    setIsGettingSession(false);

    // route to session.url
    router.push(session.url);
  };

  const formatExpiryDate = (timestamp?: number) => {
    if (!timestamp) return null;
    return new Date(timestamp * 1000).toLocaleDateString();
  };

  return (
    <div className="w-[calc(100vw-16px)] mx-auto left-1/2 -translate-x-1/2 relative overflow-hidden" id="pricing">
      <div className={clsx('bg-accent m-auto justify-center relative')}>
        <div className="overflow-hidden mx-auto max-w-4xl w-fit">
          <div className="mx-auto px-6 pt-16 pb-24 text-left md:text-center w-full flex flex-col relative">
            {coupon && (
              <Alert className="mb-8 max-w-2xl mx-auto relative">
                <div className="flex flex-col items-center">
                  <AlertTitle className="text-lg font-bold">Exclusive {discountPercent}% Discount Applied!</AlertTitle>
                  <AlertDescription className="text-sm">
                    Your special discount has been automatically applied to all packages below.
                    {expiresAt && (
                      <span className="block mt-2 font-medium">
                        ⏰ Hurry! This offer expires on {formatExpiryDate(expiresAt)}
                      </span>
                    )}
                  </AlertDescription>
                </div>
              </Alert>
            )}

            <div className="mx-auto max-w-7xl w-full">
              <div className="mb-6">
                <h2 className="text-base font-semibold leading-7 text-accent-foreground">Pricing</h2>
                <p className="mt-2 text-4xl font-bold tracking-tight text-accent-foreground sm:text-5xl mb-6 w-full">
                  {isGift ? 'Get a bundle for a friend' : 'Pro portraits without a photo shoot.'}
                </p>
                <p className="mx-auto text-lg leading-8 text-accent-foreground">
                  Get started with PicStudio.AI&apos;s Starter Kit and unlock the potential of cutting-edge AI
                  technology, transforming your selfies into captivating, personalized portraits.
                </p>
              </div>

              <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2 text-left ">
                {tiersWithDiscount.map(tier => (
                  <div
                    key={tier.id}
                    className="flex flex-col justify-between rounded-xl bg-white p-8 shadow-xl sm:p-10"
                  >
                    <div>
                      <Image src={tier.image} alt={tier.name} width={84} height={60} className="mb-4 h-auto" />
                      <div className="flex items-center gap-2 justify-between">
                        <h3 id={tier.id} className="text-lg font-semibold leading-7 text-accent">
                          {tier.name}
                        </h3>

                        {tier.mostPopular ? (
                          <p className="rounded-full px-2.5 py-1 text-xs font-semibold leading-5 text-accent-foreground bg-accent">
                            Most popular
                          </p>
                        ) : null}
                      </div>

                      <div className="flex items-center gap-x-2 mb-6">
                        {coupon ? (
                          <div className="flex flex-col items-start">
                            <span className="text-6xl font-bold tracking-tight text-black">
                              {tier.prices.discounted}
                            </span>
                            <span className="text-lg line-through text-gray-500">{tier.prices.original}</span>
                          </div>
                        ) : (
                          <span className="text-6xl font-bold tracking-tight text-black">{tier.prices.original}</span>
                        )}
                      </div>
                      <ul role="list" className="space-y-4 text-lg leading-6 text-black">
                        {tier.features.map(feature => (
                          <li key={feature} className="flex gap-x-3">
                            <CheckIcon className="h-6 w-5 flex-none text-accent" aria-hidden="true" />
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <Button
                      disabled={isGettingSession || isLoading}
                      onClick={() => handlePay(tier)}
                      aria-describedby={tier.id}
                      variant={tier.name === 'Pro Bundle' ? 'accent' : 'outline'}
                      size={'lg'}
                      className={cn(
                        'mt-8',
                        tier.name === 'Pro Bundle'
                          ? 'bg-accent'
                          : 'bg-white border-black/30 text-black hover:bg-blue-500/10'
                      )}
                      data-product-id={tier.id}
                      data-product-price={coupon ? tier.prices.discounted : tier.prices.original}
                      data-product-photo-count={tier.name === 'Pro Bundle' ? '120' : '40'}
                      data-product-pack-count={tier.name === 'Pro Bundle' ? '3' : '1'}
                      data-product-name={tier.name}
                    >
                      {isGift ? 'Gift' : 'Buy'} {tier.name === 'Pro Bundle' ? '120' : '40'} portraits
                      {isGettingSession && <CgSpinner className="animate-spin" />}
                    </Button>
                  </div>
                ))}
              </div>
              <Dialog
                open={isGiftDialogOpen}
                onOpenChange={isOpen => {
                  setIsGiftDialogOpen(isOpen);
                }}
              >
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>Gift a bundle to a friend!</DialogTitle>
                    <DialogDescription>Enter their email and we will send them the goods.</DialogDescription>
                    {errorMessage && (
                      <DialogDescription>
                        <div className="text-red-500 text-sm">{errorMessage}</div>
                      </DialogDescription>
                    )}
                  </DialogHeader>
                  <form onSubmit={handleSubmitGift}>
                    <Input
                      value={friendsEmail}
                      onChange={e => setFriendsEmail(e.target.value)}
                      id="friendsEmail"
                      label="Friends email"
                      type="email"
                    />
                    <DialogFooter>
                      <Button disabled={isGettingSession} type="submit" className="mt-4">
                        Continue {isGettingSession && <CgSpinner className="animate-spin" />}
                      </Button>
                    </DialogFooter>
                  </form>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
